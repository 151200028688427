<template>
	<v-sheet width="100%" height="100vh" class="overflow-y-auto">
		<v-card class="mb-4">
			<v-card-text class="pt-10">
				<v-slider max="100" min="0" v-model="volume" @change="onVolumeChange"
				          label="BGM볼륨"
				          dense
				          thumb-label
				          step="5"
				/>
			</v-card-text>
		</v-card>
		<v-card v-for="(audio, index) in audios" class="mb-3">
			<v-card-subtitle>
				<label>{{audio.label}}</label>
			</v-card-subtitle>
			<v-card-text>
				<div>{{ audio.name }}</div>
				<audio :src="`${apiUrl}${audio.path}`" controls />
			</v-card-text>
		</v-card>
	</v-sheet>
</template>
<script>
	export default {
		data() {
			return {
				audios: {},
				apiUrl: process.env.VUE_APP_SERVER_HOST,
				volume: 100,
			}
		},
		mounted() {
			this.axios.get(`${process.env.VUE_APP_SERVER_HOST}/api/audio`).then(response => {
				this.audios = response.data.result
			})
		},

		methods: {
			onVolumeChange() {
				$('audio:lt(5)').each((idx, audio) => {
					audio.volume = this.volume / 100
				})
			}
		}
	}
</script>

<style lang="scss" scoped>

</style>
