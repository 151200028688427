var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"fill-height"},[_c('div',{staticClass:"item-background",style:({
            backgroundImage: ("url(" + (_vm.data.background_img) + ")")
        })}),_c('div',{staticClass:"message-wrap",style:(("padding: " + (85*_vm.scale) + "px"))},[_c('pre',{staticClass:"message",style:({
					fontSize: ((32*_vm.scale) + "px"),
					color: _vm.data.text_decorations && _vm.data.text_decorations.color || '',
					background: _vm.data.text_decorations && _vm.data.text_decorations.background || '',
					textAlign: _vm.data.text_decorations && _vm.data.text_decorations.align|| '',
            	}),domProps:{"textContent":_vm._s(_vm.data.text)}})]),_c('div',{directives:[{name:"touch",rawName:"v-touch",value:({
        	start: function ($event) { return _vm.onMoveStart($event); },
            move: function ($event) { return _vm.onMove($event); },
            end: function ($event) { return _vm.onMoveEnd($event); },
    }),expression:"{\n        \tstart: ($event) => onMoveStart($event),\n            move: ($event) => onMove($event),\n            end: ($event) => onMoveEnd($event),\n    }"}],staticClass:"wrapper"},[_c('div',{staticClass:"splitter"},[_c('v-img',{attrs:{"src":"/images/swipe_splitter.png","width":"100%","height":"100%"}})],1),(_vm.data.item_answers && _vm.data.item_answers[0])?_c('div',{staticClass:"left-item",style:({ width: ((344*_vm.scale) + "px") })},[_c('img',{attrs:{"src":"/images/swipe_left.png","width":("" + (97*_vm.scale))}}),_c('pre',{staticClass:"item-text",style:({
					fontSize: ((32*_vm.scale) + "px"),
					color: _vm.data.item_answers[0].text_decorations && _vm.data.item_answers[0].text_decorations.color || '',
					background: _vm.data.item_answers[0].text_decorations && _vm.data.item_answers[0].text_decorations.background || '',
					textAlign: _vm.data.item_answers[0].text_decorations && _vm.data.item_answers[0].text_decorations.align|| '',
            	}),domProps:{"textContent":_vm._s(_vm.data.item_answers[0].text)}})]):_vm._e(),_c('div',{staticClass:"right-item",style:({ width: ((344*_vm.scale) + "px") })},[_c('div',{staticStyle:{"text-align":"right"}},[_c('img',{attrs:{"src":"/images/swipe_right.png","width":("" + (121*_vm.scale))}})]),_c('pre',{staticClass:"item-text",style:({
					fontSize: ((32*_vm.scale) + "px"),
					color: _vm.data.item_answers[1].text_decorations && _vm.data.item_answers[1].text_decorations.color || '',
					background: _vm.data.item_answers[1].text_decorations && _vm.data.item_answers[1].text_decorations.background || '',
					textAlign: _vm.data.item_answers[1].text_decorations && _vm.data.item_answers[1].text_decorations.align|| '',
            	}),domProps:{"textContent":_vm._s(_vm.data.item_answers[1].text)}})]),_c('div',{staticClass:"overlay-selector overlay-left",style:(("width: " + (50*_vm.scale) + "px; height: " + (50*_vm.scale) + "px;"))}),_c('div',{staticClass:"overlay-selector overlay-right",style:(("width: " + (50*_vm.scale) + "px; height: " + (50*_vm.scale) + "px;"))})])])}
var staticRenderFns = []

export { render, staticRenderFns }