var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"fill-height"},[_c('div',{staticClass:"item-background",style:({
        backgroundImage: ("url(" + (_vm.data.background_img) + ")")
    })}),_c('div',{staticClass:"message-wrap"},[_c('pre',{staticClass:"message",style:({
                 fontSize: ((32*_vm.scale) + "px"),
                 color: _vm.data.text_decorations && _vm.data.text_decorations.color || '',
                 background: _vm.data.text_decorations && _vm.data.text_decorations.background || '',
                 textAlign: _vm.data.text_decorations && _vm.data.text_decorations.align || '',
             }),domProps:{"textContent":_vm._s(this.data.text)}}),_vm._v(" "),_c('v-btn',{staticClass:"my-primary-color btn-start",attrs:{"rounded":"","width":"65%","x-large":""},on:{"click":function($event){return _vm.$emit('change')}}},[_vm._v(_vm._s(_vm.data.message))])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }