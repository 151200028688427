<template>
    <v-layout column class="fill-height">
        <v-layout
            justify-center
            align-center
            class="popup"
            >
            <v-card elevation="0" class="message-popup">
                <v-card-title class="message-title" :style="`font-size: ${30*scale-4}px;`">
                    <v-spacer></v-spacer>
                    MESSAGE
                    <v-spacer></v-spacer>
                </v-card-title>
                <v-card-text class="pa-0">
                    <v-layout class="message-body" align-center :style="`padding: ${72*scale}px ${37*scale}px`">
                        <img src="/images/kakao_icon.png" :style="`width: ${69*scale}px`" />
                        <pre v-text="data.message"
                             :style="{
                                fontSize: `${32*scale}px`,
                            }"
                        />
                    </v-layout>
                </v-card-text>
                <v-card-actions class="message-buttons" :style="`font-size: ${32*scale}px;`">
                    <pre
                        class="btn-answer"
                        :style="`height: ${174*scale}px`"
                        v-for="(item, index) in data.item_answers"
                        v-if="index < 2"
                        v-text="item.text"
                        @click="onSelect(index)"
                        >
                    </pre>
                </v-card-actions>
            </v-card>
        </v-layout>
        <v-layout
            justify-center
            align-center
            class="message"
            :style="{
                fontSize: `${40*scale}px`,
                background: data.text_decorations && data.text_decorations.background || '',
                }"
            >
                <pre v-text="data.text"
                     style="width: 80%; padding-bottom: 15%;"
                     :style="{
                        fontSize: `${38*scale}px`,
                        color: data.text_decorations && data.text_decorations.color || '',
                        textAlign: data.text_decorations && data.text_decorations.align|| '',
                    }"
                />
        </v-layout>
    </v-layout>

</template>
<script>
    export default {
    	data() {
    		return {
            }
        },
        computed: {
            scale() {
                return this.$store.getters.scale
            },
        },
    	mounted() {

            if (this.data && this.data.item_answers) {
                this.data.item_answers.forEach(item => {
                    // item.text = item.text && item.text.replace(/\n/g, '<br />')

                    if (item.text_decorations && typeof item.text_decorations === 'string') {
                        item.text_decorations = JSON.parse(item.text_decorations)
                    }
                })
            }
            this.$store.commit('changeProgressColor', 1)
	    },
        props: [
        	'data',
        ],

        watch: {
            data: {
                deep: true,
                handler(val) {
                    val.item_answers.forEach(item => {
                        // item.text = item.text && item.text.replace(/\n/g, '<br />')
                    })
                    $('.btn-answer').removeClass('active')
                }
            }
        },
	    methods: {
    		onSelect(index) {
    			$('.btn-answer').removeClass('active')
                $(`.btn-answer:eq(${index})`).addClass('active')

                this.$store.commit('setUserAction', 'kakao')

                this.$emit('change', this.data.item_answers[index])
            }
	    }
    }
</script>
<style lang="scss" scoped>

    .popup {
        flex: 1;
        .message-popup {
            background-image: linear-gradient(311deg, #ffffff 99%, #fcfcfd 58%, #f2f3f7 14%);
            box-shadow: 20px 20px 30px 0 rgba(15, 41, 107, 0.12) !important;
            border-radius: 10px !important;

            .message-title {
                background-color: #1985FB;
                color: #ffffff;
                padding: 0;
                width : 80vw;
            }

            .message-body {
                width: 100%;

                & > pre {
                    padding: 15px;
                }
            }

            .message-buttons {
                border-top: 1px solid #C3C3C3;
                align-items: start;
                padding: 0 !important;

                & > pre {
                    display: flex;
                    align-items: center;
                    justify-content: center;
	                width: 50%;
                    border-right :1px solid #C3C3C3;

                    color: #1985FB;
                    border-bottom-left-radius: 10px;

                    &.active {
                        color: #ffffff;
                        background: #1985FB;
                    }
                }

                pre+pre {
                    border-left: 1px solid #C3C3C3;
                    border-right: 0;
                    margin-left: -1px;
                    border-bottom-left-radius: 0;
                    border-bottom-right-radius: 10px;
                }
            }
        }
    }

    .message {
        flex: 1;
        background: #272727;
        color: #efefef;
    }

    @media screen and (min-width: 768px) {
        .message-title { padding: 20px !important;}
    }
</style>

