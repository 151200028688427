import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)
// Vue.use(VueLodash, { name: 'custom' , lodash: lodash })

export default new Router({
    mode: 'history',
    routes: [
        {
            path: '/',
	        redirect: 'login',
        },
        {
            path: '/login',
            name: 'Login',
            component: require('../components/login/Login').default
        },
        {
            path: '/agreement',
            name: 'Agreement',
            component: require('../components/login/Agreement').default
        },
        {
            path: '/item/:id',
            name: 'Item',
            component: require('../components/items/Item').default
        },
        {
            path: '/result',
            name: 'Result',
            component: require('../components/items/Result').default
        },
        {
            path: '/result/list',
            name: 'ResultList',
            component: require('../components/items/ResultList').default
        },
        {
            path: '/result/:id',
            name: 'ResultById',
            component: require('../components/items/Result').default
        },
        {
            path: '/tests/audio',
            name: 'AudioTest',
            component: require('../components/tests/Audio').default
        },

    ]
})
