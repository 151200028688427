var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"fill-height"},[_c('div',{staticClass:"item-background",style:({
            backgroundImage: ("url(" + (_vm.data.background_img) + ")")
        })}),_c('pre',{staticClass:"message",style:({
                 fontSize: ((32*_vm.scale) + "px"),
                 color: _vm.data.text_decorations && _vm.data.text_decorations.color || '',
                 background: _vm.data.text_decorations && _vm.data.text_decorations.background || '',
                 textAlign: _vm.data.text_decorations && _vm.data.text_decorations.align|| '',
                 top: "10%",
                 left: "19.5%",
                 right: "19.5%",
             }),domProps:{"textContent":_vm._s(this.data.text)}}),_vm._v(" "),(_vm.data.action_type === 'button')?_c('div',{staticClass:"button-wrap"},[_c('v-btn',{staticClass:"my-primary-color btn-start",attrs:{"rounded":"","width":"65%","x-large":""},on:{"click":function (v) {_vm.$emit('change'); this$1.$store.commit('setUserAction', 'text')}}},[_vm._v(_vm._s(_vm.data.message))])],1):_vm._e(),(_vm.data.action_type === 'swipe')?_c('div',{directives:[{name:"touch",rawName:"v-touch",value:({
        	start: function ($event) { return _vm.onMoveStart($event); },
            move: function ($event) { return _vm.onMove($event); },
            end: function ($event) { return _vm.onMoveEnd($event); },
    }),expression:"{\n        \tstart: ($event) => onMoveStart($event),\n            move: ($event) => onMove($event),\n            end: ($event) => onMoveEnd($event),\n    }"}],staticClass:"swipe-wrap"},[_c('div',{staticClass:"right-item"},[_c('div',{staticStyle:{"text-align":"right"}},[_c('img',{attrs:{"src":"/images/swipe_right.png","width":("" + (121*_vm.scale))}})]),_c('pre',{staticClass:"item-text",style:({
					fontSize: ((32*_vm.scale) + "px"),
					color: _vm.data.message_decorations && _vm.data.message_decorations.color || '',
					message: _vm.data.message_decorations && _vm.data.message_decorations.message || '',
					textAlign: _vm.data.message_decorations && _vm.data.message_decorations.align|| '',
            	}),domProps:{"textContent":_vm._s(_vm.data.message)}})]),_c('div',{staticClass:"overlay-selector overlay-right",style:(("width: " + (50*_vm.scale) + "px; height: " + (50*_vm.scale) + "px;"))})]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }