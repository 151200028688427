<template>
    <v-layout align-center justify-center fill-height class="item-page-wrap">
        <div class="item-page" v-touch="{

             }">
            <div class="item-box">
                <intro-widget v-if="itemData.type === 'intro'" :data="itemData" @change="goNext" />
                <item-widget-swipe v-if="itemData.type === 'swipe'" :data="itemData" @change="goNext" />
                <item-widget-image v-if="itemData.type === 'image'" :data="itemData" @change="goNext" />
                <item-widget-text v-if="itemData.type === 'text'" :data="itemData" @change="goNext" />
                <item-widget-kakao v-if="itemData.type === 'kakao'" :data="itemData" @change="goNext" />
                <item-widget-messenger v-if="itemData.type === 'messenger'" :data="itemData" @change="goNext" />
                <item-widget-title-leaf v-if="itemData.type === 'titleleaf'" :data="itemData" @change="goNext" />
            </div>
        </div>
        <div class="progress-bar" v-if="sequence !== 0 && itemData.type !== 'titleleaf'">
            <v-progress-linear buffer-value="100" :value="progress" rounded height="9"
                               :color="progressColors[progressColorIndex].foreground"
                               :background-color="progressColors[progressColorIndex].background"
            />
        </div>
    </v-layout>
</template>

<script>
    import IntroWidget from "../widgets/IntroWidget";
    import ItemWidgetSwipe from "../widgets/ItemWidgetSwipe";
    import ItemWidgetImage from "../widgets/ItemWidgetImage";
    import ItemWidgetText from '../widgets/ItemWidgetText.vue';
    import ItemWidgetKakao from '../widgets/ItemWidgetKakao.vue';
    import ItemWidgetMessenger from '../widgets/ItemWidgetMessenger.vue';
    import ItemWidgetTitleLeaf from '../widgets/ItemWidgetTitleLeaf.vue';

    export default {
        name: 'Item',
        components: {
            IntroWidget,
            ItemWidgetSwipe,
            ItemWidgetImage,
            ItemWidgetText,
            ItemWidgetKakao,
            ItemWidgetMessenger,
            ItemWidgetTitleLeaf,
        },
        data() {
            return {
                progressColors: [
                    {foreground: '#FF773B', background: 'rgba(191,191,191,0.35)',},
                    {foreground: '#FFFFFF', background: 'rgba(255,255,255,0.35)',},
                ],
                itemData: {},
                sequence: 0,
            }
        },
        watch: {
            $route(to) {
                if (to.params) {
                    this.sequence = parseInt(to.params.id)
                    this.setItemData()
                }
            },

            items(val) {
            	if (val && val.length > 0) {
            	    this.setItemData()
                }
            }
        },
        computed: {
        	items() {
        		return this.$store.getters.items
            },
            progress() {
                return this.sequence / this.items.length * 100
            },
            progressColorIndex() {
                return this.$store.getters.progressColorIndex
            }
        },

        beforeRouteEnter(to, from, next) {
            next(vm => {
                vm.$store.commit('showAppBar')
            })
        },
        mounted() {
            this.sequence = parseInt(this.$route.params.id) || 0

            if (this.items) {
                this.setItemData()
            }
        },
        methods: {
            setItemData() {
                const data = this.items[this.sequence]
	            if (!data) return
                if (data.text_decorations && typeof data.text_decorations === 'string') {
                    data.text_decorations = JSON.parse(data.text_decorations)
                }
                if (data.message_decorations && typeof data.message_decorations === 'string') {
                    data.message_decorations = JSON.parse(data.message_decorations)
                }
                if (data.text) {
                    // data.text = data.text.replace(/\n/g, '<br />')
                }
                if (data.message) {
                    // data.message = data.message.replace(/\n/g, '<br />')
                }

                this.itemData = data

                this.$store.commit('setItem', data)

            },

            goNext(answer) {
                // $('.btn-fullscreen').click()

                const params = {
                    answer: answer
                }


                if (this.sequence === 0) {
                    if (document.documentElement.requestFullscreen) {
                        document.documentElement.requestFullscreen()
                    }
                }

                this.axios.put(`${process.env.VUE_APP_SERVER_HOST}/api/item/${this.sequence}`, params).then(response => {
                    setTimeout(_ => {
                        if (response.data.code === '0000') {
                        const progress = (this.sequence + 1) / this.items.length * 100

                            if (progress >= 100) {
                                this.$router.push(`/result`)
                            } else {
                                this.$router.push(`/item/${parseInt(this.sequence) + 1}`)
                            }

                        }
                        this.$store.commit('hideLoader')
                        $('.item-box').fadeIn(500)
                    }, 500)

                })

                this.$store.commit('showLoader')
                $('.item-box').fadeOut(500, _ => {
                })
            }
        }
    }
</script>

<style lang="scss" scoped>
    .item-page-wrap {
        padding-top: calc(174*(100vw/720));
    }
    .item-page {
        width: 100%;
        /*max-width: 550px;*/
        height: calc(100vh - (174*(100vw/720)));
        position: relative;


        .item-box {
            position: absolute;
            left: 0; top: 0;
            width: 100%;
            height: 100%;
        }
    }

</style>
