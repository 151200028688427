<template>
	<div>
		<div class="result-wrap">
			<div class="result" v-if="result">
				<img :src="result.image" />

				<v-layout class="relative" justify-center>
					<div>
						<img :src="`https://my-weekly.s3.ap-northeast-2.amazonaws.com/results/icon_result_${result.related_1}.png`" @click="moveTo(result.related_1)" />
					</div>
					<div>
						<img :src="`https://my-weekly.s3.ap-northeast-2.amazonaws.com/results/icon_result_${result.related_2}.png`" @click="moveTo(result.related_2)" />
					</div>
				</v-layout>

				<div class="buttons">
					<img src="/images/btn_result_download.png" @click="downloadImage(result.result_num)" />
					<img src="/images/btn_other_result.png" @click="$router.push('/result/list')" />
				</div>
			</div>
		</div>
		<div class="result-loader">
			<div>두근..</div>
			<vue-loaders name="ball-pulse-sync"></vue-loaders>
			<div>당신의 유형은?</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				id: null,
				result: null,
			}
		},
		watch: {
			'$route.path': {
				handler(val) {

					this.id = this.$route.params.id

					this.getResult()
				}
			}

		},
		beforeRouteEnter(to, from, next) {
			next(vm => {
				vm.$store.commit('showAppBar')

				vm.id = vm.$route.params.id
				if (!vm.id) {
					setTimeout(_ => {
						$('.result-loader').fadeOut('fast');
					}, 2000)
				} else {
					$('.result-loader').hide()
				}

			})
		},

		filters: {
			lpad(val) {
				if (!val) return ''


				if (val.toString().length < 2) {
					val = '0' + val
				}

				return val
			}
		},

		mounted() {
			this.$store.commit('setItem', null)

			this.id = this.$route.params.id
			if (!this.id) {
				setTimeout(_ => {
					$('.result-loader').fadeOut('fast');
				}, 2000)
			} else {
				$('.result-loader').hide()
			}

			this.getResult()
		},

		methods: {

			getResult() {

				const params = {
					id: this.id
				}

				this.axios.get(`${process.env.VUE_APP_SERVER_HOST}/api/result`, {params: params}).then(response => {
					this.result = response.data.result
					$('.result-wrap').fadeIn('fast')
					$('.result-wrap').scrollTop(0)
				})
			},

			moveTo(id) {
				$('.result-wrap').fadeOut('fast', _ => {
					this.result = null
					this.$router.push(`/result/${id}`)
				})
			},

			downloadImage() {
				const url = `https://my-weekly.s3.ap-northeast-2.amazonaws.com/results/dn/result_${this.result.result_num}.png`
				const link = document.createElement('a')
				link.download = `result_${this.$options.filters.lpad(this.result.result_num)}.png`
				link.type = 'application/octet-stream'
				link.target = '_blank'
				link.href = url
				link.click()
			}
		}
	}
</script>

<style lang="scss" scoped>
	img {
		max-width: 100%;
	}

	.result-wrap {
		overflow-y: scroll;
		height: 100vh;

		.result {
			margin-top: calc(174 * (100vw / 720));
			position:relative;
		}

		.relative {
			position: absolute;
			left: 0;
			width: 100%;
			bottom: calc(490*(100vw/720));
			padding: 0 calc(47*(100vw/720)) 0 calc(47*(100vw/720));

			div {
				flex-grow: 1;
				text-align: center;
			}
			div+div {
				margin-left: calc(28*(100vw/720))
			}
		}

		.buttons {
			position: absolute;
			left: 0;
			width: 100%;
			bottom: calc(90*(100vw/720));
			padding: 0 calc(135*(100vw/720)) 0 calc(165*(100vw/720));
			line-height: 0;

			img+img {
				margin-top: 15px;
			}
		}
	}

	.result-loader {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		background-color: #FFD6AE;
		color: #797167;
		font-size: calc(50*(100vw/720));
		font-family: GmarketSans, sans-serif !important;
		z-index: 50000;
	}

	@media screen and (min-width: 480px) {
		.result-wrap {

			.result {
				width: 480px;
				margin: 0 auto;
			}

			.relative {
				bottom: calc(415*(480px/720));
				padding: 0 calc(27*(480px/720)) 0 calc(34*(480px/720));

				div+div {
					margin-left: calc(28*(480px/720))
				}
			}

			.buttons {
				bottom: calc(110*(480px/720));
				padding: 0 calc(135*(480px/720)) 0 calc(165*(480px/720));
			}
		}
	}
</style>
