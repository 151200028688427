var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"fill-height"},[_c('div',{staticClass:"item-background",style:({
            backgroundImage: ("url(" + (_vm.data.background_img) + ")")
        })}),_c('v-layout',{staticClass:"message-wrap",style:(("padding: " + (270*_vm.scale) + "px " + (85*_vm.scale) + "px " + (85*_vm.scale) + "px")),attrs:{"fill-height":"","column":""}},[_c('pre',{staticClass:"message",style:({
					fontSize: ((32*_vm.scale) + "px"),
					color: _vm.data.text_decorations && _vm.data.text_decorations.color || '',
					background: _vm.data.text_decorations && _vm.data.text_decorations.background || '',
					textAlign: _vm.data.text_decorations && _vm.data.text_decorations.align || '',
            	}),domProps:{"textContent":_vm._s(_vm.data.text)}}),_c('v-layout',{staticClass:"answer-wrap",attrs:{"column":"","justify-center":""}},[_c('div',{staticClass:"d-inline-block"},_vm._l((_vm.data.item_answers),function(item,index){return _c('v-layout',{staticClass:"btn-answer",style:(("font-size: " + (32*_vm.scale) + "px; margin-bottom: " + (30*_vm.scale) + "px; padding: 14px")),attrs:{"justify-center":"","align-center":""},on:{"click":function($event){return _vm.onSelect(index)}}},[_vm._v(" "+_vm._s(item.text)+" ")])}),1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }