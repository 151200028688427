<template>
    <v-layout column class="fill-height">
        <v-layout
            column
            justify-start
            align-center
            class="messenger"
            :style="`font-size: ${40*scale}px; padding-top: 35px`"
            >
                <pre class="message-title" v-text="data.text"
                     :style="{
                        width: '80%',
                        fontSize: `${32*scale}px`,
                    }"
                />

                <div class="user" :style="`width: 80%; margin-top: ${30*scale}px; font-size: ${30*scale}px`">
                    <img src="/images/kakao_icon.png" :style="`width:${69*scale}px`" />
                    김ㅇㅇ <span class="time" :style="`font-size:${24*scale}px`">18:00</span>
                </div>

                <pre class="message" v-text="data.message"
                     :style="{
                            width: '80%',
                            padding: `${20*scale}px ${40*scale}px`,
                            fontSize: `${32*scale}px`,
                        }"
                />
        </v-layout>
        <v-layout
            justify-start
            align-center
            column
            class="answers"
            :style="`font-size: ${32*scale}px; padding: ${40*scale}px;`"
            >
            <div class="btn-answer" style="width:80%" :style="{
                    padding: `${24*scale}px`,
                    marginTop: `${index > 0 ? 21*scale : 0}px`,
                }"
                 v-for="(item, index) in data.item_answers"
                 v-if="index < 2"
                @click="onSelect(index)"
            >
	            <pre v-text="item.text"></pre>
                <button class="btn" :style="`font-size: ${30*scale}px; padding: ${5*scale}px ${20*scale}px;`">전송</button>
            </div>

        </v-layout>
    </v-layout>

</template>
<script>
    export default {
    	data() {
    		return {
            }
        },
        computed: {
            scale() {
                return this.$store.getters.scale
            },
        },
    	mounted() {

            if (this.data && this.data.item_answers) {
                this.data.item_answers.forEach(item => {
                    // item.text = item.text && item.text.replace(/\n/g, '<br />')

                    if (item.text_decorations && typeof item.text_decorations === 'string') {
                        item.text_decorations = JSON.parse(item.text_decorations)
                    }
                })
            }

            this.$store.commit('changeProgressColor', 1)
	    },
        props: [
        	'data',
        ],

        watch: {
            data: {
                deep: true,
                handler(val) {
                    val.item_answers.forEach(item => {
                        // item.text = item.text && item.text.replace(/\n/g, '<br />')
                    })
                    $('.btn-answer').removeClass('active')
                }
            }
        },
	    methods: {
    		onSelect(index) {
    			$('.btn-answer').removeClass('active')
                $(`.btn-answer:eq(${index})`).addClass('active')

                this.$store.commit('setUserAction', 'messenger')

                this.$emit('change', this.data.item_answers[index])
            }
	    }
    }
</script>
<style lang="scss" scoped>

    .messenger{
        flex: 1;
        background-color: #f2f3f7;

        .user {
            display: flex;
            align-items: center;

            img {
                display: inline-block;
                margin-right: 10px;
            }
            .time {
                display: inline-block;
                margin-left: 10px;
                color: #9B9B9B;
            }
        }

        .message {
            box-shadow: 10px 10px 10px 0 rgba(15, 41, 107, 0.12);
            border: solid 1px rgba(255, 255, 255, 0.5);
            background-image: linear-gradient(289deg, #ffffff 50%, #fcfcfd 75%, #f2f3f7 95%);
            border-radius: 3em;
            margin-top: 3%;
        }
    }

    .answers {
        background-color: #F9CB51;
        flex: 1;

        .btn-answer {
            background-color: #FFFFFF;
            border-radius: 7px;

            button {
                float:right;
                background-color: #D1D1D1;
                border-radius: 5px;
                color: #ffffff;
                margin-right: -5px;
            }

            &.active {
                background-color: #1985FB;
                color: #ffffff;

                button {
                    background: #ffffff;
                    color: #1985FB;
                }
            }
        }
    }


    @media screen and (min-width: 768px) {
    }
</style>

