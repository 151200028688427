<template>

    <div class="fill-height">
        <div class="item-background" :style="{
            backgroundImage: `url(${data.background_img})`
        }">
        </div>
        <v-layout class="message-wrap" fill-height column :style="`padding: ${230*scale}px ${85*scale}px ${85*scale}px`">
            <pre class="message" v-text="data.text"
                 :style="{
					fontSize: `${32*scale}px`,
					color: data.text_decorations && data.text_decorations.color || '',
					background: data.text_decorations && data.text_decorations.background || '',
					textAlign: data.text_decorations && data.text_decorations.align|| '',
            	}"
            />

            <v-layout class="answer-wrap" justify-center align-center>
                <v-row>
                    <v-col cols="6" class="position-relative" v-for="(item, index) in data.item_answers">
                        <div ref="btnSelect" class="btn-answer d-flex justify-center align-center flex-column" @click="onSelect(index)" >
                            <div>
                                <img :src="item.image" />
                            </div>
                        </div>
                    </v-col>
                </v-row>
            </v-layout>
        </v-layout>
    </div>

</template>
<script>
    export default {
    	data() {
    		return {
            }
        },
        computed: {
            scale() {
                return this.$store.getters.scale
            }
        },
    	mounted() {

            if (this.data && this.data.item_answers) {
                this.data.item_answers.forEach(item => {
                    // item.text = item.text && item.text.replace(/\n/g, '<br />')

                    if (item.text_decorations && typeof item.text_decorations === 'string') {
                        item.text_decorations = JSON.parse(item.text_decorations)
                    }
                })
            }


            this.$store.commit('changeProgressColor', 0)
	    },
        props: [
        	'data',
        ],

        watch: {
            data: {
                deep: true,
                handler(val) {
                    val.item_answers.forEach(item => {
                        // item.text = item.text && item.text.replace(/\n/g, '<br />')
                    })
                    $('.btn-answer').removeClass('active')
                }
            }
        },
	    methods: {
    		onSelect(index) {
    			$('.btn-answer').removeClass('active')
                $(`.btn-answer:eq(${index})`).addClass('active')

                this.$store.commit('setUserAction', 'image')

                this.$emit('change', this.data.item_answers[index])
            }
	    }
    }
</script>
<style lang="scss" scoped>
    .item-background {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
    .message-wrap {
        position: absolute;
        z-index: 10;
        width: 100%;
        height: 100%;
        color: #000000;
        max-width: 100%;

        .message {
            font-weight: 600;
            line-height: 1.43;
        }
    }

    .btn-answer {
        background-image: linear-gradient(135deg, #e8ebf2, #f2f3f7);
        border: 1px solid rgba(255,255,255,0.5);
        border-radius: 18px;
        box-shadow: 10px 10px 15px 0 rgba(15, 41, 107, 0.12);
        position: relative;

        &:after {
            content: '';
            display: block;
            padding-bottom: 100%;
        }
	    div {
            position: absolute;
            width: 100%;
            padding: 7px;

            img {
                width: 100%;
            }
        }


        &.active {
            border: 5px solid #f9cb51;
            background-image: linear-gradient(137deg, #f5f2ea 3%, #fffef5 99%);
        }
    }

    @media screen and (min-width: 768px) {
        .answer-wrap {
            padding: 15%;
        }
    }


</style>
