<template>
    <div class="agreement-wrap">
        <div class="agreement-title">
            <img src="/images/login_logo.png" />
        </div>
        <div class="agreement-box">
            <v-subheader class="header">「개인정보수집·이용·제공 동의」</v-subheader>
            <pre class="agreement-content" v-text="agreement"></pre>
            <div class="check-agree">
                <span class="round">
                    <input type="checkbox" id="agree" v-model="agree" />
                    <label for="agree"></label>
                </span>
                <label for="agree">위와 같이 본인의 개인정보를 수집·이용·제공하는 것에 동의합니다.</label>
            </div>
            <div class="button-wrap">
<!--                <v-btn rounded class="btn-agree kb-primary-color" width="191" height="60" large @click="goNext">시작하기</v-btn>-->
                <img src="/images/btn_start.png" @click="goNext" />
            </div>
        </div>
    </div>
</template>

<script>
    export default {
    	name: 'Agreement',
        data() {
    		return {
                agree: false,
            }
        },

        computed: {
            scale() {
                return this.$store.getters.scale
            },
            agreement() {
            	return this.$store.getters.agreement
            }
        },

	    beforeRouteEnter(to, from, next) {
		    next(vm => {
			    vm.$store.commit('hideAppBar')
		    })
	    },
        beforeCreate() {
        },
	    beforeMount() {

        },
        methods: {
    		goNext() {
    			if (!this.agree) {
    				this.$store.commit('showDialog', {
    					type: 'alert',
                        message: '개인정보수집·이용·제공에 동의해주세요.',
                    })
                    return
                }
			    this.axios.put(`${process.env.VUE_APP_SERVER_HOST}/api/agree`).then(response => {

				    if (document.documentElement.requestFullscreen) {
					    document.documentElement.requestFullscreen()
				    }
				    this.$router.push('/item/0')
			    })
		    }
        }
    }
</script>

<style lang="scss" scoped>

    .agreement-wrap {
        background: #FFB273;
        height: 100%;
        overflow-y: auto;
    }

    .check-agree {
        color: #ffffff;
        text-align: left;
        display: inline-flex;
        justify-content: flex-start;
        align-items: flex-start;
        width: 100%;
        margin-top: calc(33*(100vw/720));
        font-size: calc(25*(100vw/720));

        .round {
            position: relative;
            width: calc(45*(100vw/720));
            height: calc(45*(100vw/720));
            display: inline-block;
            margin-right: 8px;
        }

        .round label {
            border: 1px solid #fff;
            border-radius: 50%;
            cursor: pointer;
            height: calc(45*(100vw/720));
            left: 0;
            position: absolute;
            top: 0;
            width: calc(45*(100vw/720));
        }

        .round label:after {
            border: 2px solid #fff;
            border-top: none;
            border-right: none;
            content: "";
            width: calc(25*(100vw/720));
            height: calc(15*(100vw/720));
            opacity: 1;
            position: absolute;
            left: calc(8*(100vw/720));
            top: calc(8*(100vw/720));
            transform: rotate(-45deg);
        }

        .round input[type="checkbox"] {
            position: relative;
            z-index: 9;
            opacity: 0;
            width: 100%;
            height: 100%;
        }

        .round input[type="checkbox"]:checked+label {
            background-color: #FF773B;
            border-color: #FF773B;
        }

        .round input[type="checkbox"]:checked+label:after {
            opacity: 1;
        }

        & > label {
            line-height: 2;
            color: #3A3732;
            width: calc(525*(100vw/720));
        }

    }

    .agreement-title{
        text-align: center;
        padding-top: calc(6*(100vw/720));
        img {
            width: calc(352*(100vw/720));
        }
    }

    .agreement-content {
        padding: 18.5px 13.8px 20.8px 24.6px;
        border-radius: 16px;
        /*border: solid 0.5px #707070;*/
        background-color: #ffffff;
        margin-top: 2%;
        height: calc(457*(100vh/1280));
        overflow-y: scroll;
        font-size: calc(25*(100vw/720));
        font-family: GmarketSans, sans-serif !important;
    }

    .agreement-box {
        margin: 0 calc(47*(100vw/720)) calc(30*(100vw/720));

        .header {
            color: #3A3732;
            font-size: calc(30*(100vw/720));
        }
    }

    .button-wrap {
        display: flex;
        min-height: calc(240*(100vw/720));
        height: 100%;
        justify-content: center;
        align-items: flex-end;
        padding-bottom: calc(58*(100vw/720));

        /*.btn-agree {*/
        /*    font-size: 20px;*/
        /*    color: #ffffff;*/
        /*}*/

        img {
            display: inline-block;
            width: calc(400*(100vw/720));
        }
    }

</style>
