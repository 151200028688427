import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueCookie from 'vue-cookie'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import eventBus from './plugins/EventBus'
import store from './plugins/Store'

import 'vue-loaders/dist/vue-loaders.css';
import VueLoaders from "vue-loaders"
import './registerServiceWorker'

Vue.use(VueLoaders)

global.jQuery = require('jquery');
const $ = global.jQuery;
window.$ = $;

Vue.config.productionTip = false

Vue.use(eventBus)
Vue.use(VueAxios, axios)
Vue.use(VueCookie)

const vm = new Vue({
	vuetify,
	eventBus,
	router,
	store,
	render: h => h(App)
}).$mount('#app')

window.vm = vm
