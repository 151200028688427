import { render, staticRenderFns } from "./AudioWidget.vue?vue&type=template&id=08dd0db6&scoped=true&"
import script from "./AudioWidget.vue?vue&type=script&lang=js&"
export * from "./AudioWidget.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "08dd0db6",
  null
  
)

export default component.exports

/* vuetify-loader */
import installDirectives from "!../../../node_modules/vuetify-loader/lib/runtime/installDirectives.js"
import Touch from 'vuetify/lib/directives/touch'
installDirectives(component, {Touch})
